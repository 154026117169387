import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DesktopService } from '../../services/desktop.service';
import { ModuleManagerService } from '../../services/module-manager.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
   selector: 'asc-settings',
   templateUrl: './settings.component.html',
   styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
   public title = 'Einstellungen';

   constructor(
      private activatedRoute: ActivatedRoute,
      private desktopService: DesktopService,
      private moduleManagerService: ModuleManagerService,
      private navigationService: NavigationService
   ) {
      const hasSettingsModules = this.moduleManagerService.settingsNavigationItems.length > 0;
      if (this.isSettingsPanelVisible && !this.hasChildRoutes && hasSettingsModules) {
         // Propagate navigation to 1st settings module
         this.moduleManagerService.settingsNavigationItems[0].entryPoints[0].navigate();
      }
   }

   // -------------------------------------------------------------- Properties

   private get hasChildRoutes(): boolean {
      return this.activatedRoute.children.length > 0;
   }

   public get isSettingsMenuVisible(): boolean {
      return this.desktopService.canShowSettingsPanel || !this.hasChildRoutes;
   }

   public get isSettingsPanelVisible(): boolean {
      return this.desktopService.canShowSettingsPanel || this.hasChildRoutes;
   }

   // -------------------------------------------------------------- Operations

   public navigateBack() {
      this.navigationService.navigateToSettings();
   }
}
