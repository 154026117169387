import { LOCALE_ID, NgModule, APP_INITIALIZER, InjectionToken } from '@angular/core';
import { AsyncPipe, registerLocaleData } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDeCH from '@angular/common/locales/de-CH';
import { RouterModule } from '@angular/router';

import { SharedModule } from './shared.module';

// UI modules
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MenuComponent } from './components/menu/menu.component';
import { SettingsMenuComponent } from './components/settings-menu/settings-menu.component';

import { DashboardComponent } from './views/dashboard/dashboard.component';

import { AuthenticationModule } from './authentication/authentication.module';

// Services
import { BusyService } from './services/busy.service';
import { CoreService } from './services/core.service';
import { DesktopService } from './services/desktop.service';
import { ModuleManagerService } from './services/module-manager.service';
import { SessionService } from './services/session.service';
import { SettingsComponent } from './views/settings/settings.component';
import { CORE_ROUTER_MODULE } from './core-router.module';
import { AscModuleIconModule } from './components/module-icon/module-icon.module';
import { HttpRequestCachingInterceptor } from './services/http-request-caching.interceptor';
import { DataSourceAvailabilityInterceptor } from './services/data-source-availability.interceptor';
import { HttpRequestCachingService } from './services/http-request-caching.service';
import { AscNotificationBarComponent } from './components/notification-bar/notification-bar.component';
import { NotificationBarService } from './services/notification-bar.service';
import { ClientConfiguration } from './model/client-configuraton';
import { FunctionClient } from './azure-functions';
import { NavigationService } from './services/navigation.service';
import { TelemetryService } from './services/telemetry.service';
import { AuthenticationService } from './authentication/services/authentication.service';
import { FormModule } from './form/form.module';

export const CLIENT_CONFIG = new InjectionToken<ClientConfiguration>('client.config');


@NgModule({
    declarations: [
        MenuComponent,
        SettingsComponent,
        SettingsMenuComponent,
        DashboardComponent,
        AscNotificationBarComponent
    ],
    imports: [
        SharedModule,
        RouterModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatTooltipModule,
        AscModuleIconModule,
        AuthenticationModule,
        FormModule,
        CORE_ROUTER_MODULE], providers: [
            { provide: LOCALE_ID, useValue: 'de-CH' },
            { provide: HTTP_INTERCEPTORS, useClass: HttpRequestCachingInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: DataSourceAvailabilityInterceptor, multi: true },
            {
                provide: APP_INITIALIZER,
                deps: [
                    HttpClient,
                    TelemetryService,
                    AuthenticationService,
                    CoreService,
                    ModuleManagerService,
                    SessionService
                ],
                useFactory: (httpClient: HttpClient, telemetryService: TelemetryService, authenticationService: AuthenticationService, coreService: CoreService, moduleManagerService: ModuleManagerService, sessionService: SessionService) => {
                    // Initialize function-controllers
                    FunctionClient.httpClient = httpClient;
                    // Initialize client configuration
                    return async () => {
                        // Load client configuration
                        await coreService.loadClientConfigurationAsync();
                        const clientConfiguration = coreService.clientConfiguration;
                        // Initialize Application Insights
                        telemetryService.config = {
                            instrumentationKey: clientConfiguration.telemetry.applicationInsightsInstrumentationKey
                        };
                        telemetryService.init();
                        // Initialize authentication module
                        await AuthenticationModule.initializeAsync(clientConfiguration, authenticationService);
                        // Initialize core module
                        moduleManagerService.initialize(clientConfiguration);
                        sessionService.initialize(clientConfiguration);
                        // Handle sign-in
                        let isSignedIn = authenticationService.loginFromUrlParameters();
                        if (!isSignedIn) {
                            isSignedIn = await authenticationService.loginAfterRedirectAsync();
                        }
                        if (!isSignedIn) {
                            // Silient sign-in
                            isSignedIn = await authenticationService.loginSSOAsync(true);
                        }
                    };
                },
                multi: true
            },
            HttpRequestCachingService,
            AsyncPipe,
            BusyService,
            CoreService,
            DesktopService,
            ModuleManagerService,
            NavigationService,
            NotificationBarService,
            SessionService,
            TelemetryService,
            provideHttpClient(withInterceptorsFromDi())
        ],
    exports: [
        SharedModule,
        RouterModule,
        AscNotificationBarComponent,
        MenuComponent
    ]
})
export class CoreModule {
    constructor() {
        registerLocaleData(localeDeCH);
    }
}
